import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, Event, RouteConfigLoadStart, RouteConfigLoadEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ReservationService } from 'src/app/core/http/reservation.service';
import { SidebarService } from 'src/app/core/services/sidebar.service';
import { URL_PROVIDER_ORIGINS, URL_PROVIDER_ORIGINS_SEARCHER } from 'src/app/shared/model/reservation.model';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnDestroy {

	isShowingRouteLoadIndicator: boolean;
	private routerSubscription: Subscription;
  private routerParamsSubscription: Subscription;

	@ViewChild(MatSidenav, { static: true }) sidenav!: MatSidenav;

	constructor(private router: Router, private sidebarService: SidebarService, private activatedRoute: ActivatedRoute, private reservationService: ReservationService) {
    this.isShowingRouteLoadIndicator = false;
		let asyncLoadCount = 0;
    this.routerParamsSubscription = this.activatedRoute.queryParams.subscribe(params => {

      let urlIframe = window.location.href;
      
      if(params?.clientOrigin){
        
        let urlOriginProvider = URL_PROVIDER_ORIGINS.DIRECTO;

        if(params?.clientOriginParams){
          
          reservationService.setUrlOrigin(`parent=${params.clientOrigin}?${params.clientOriginParams}`);

          urlOriginProvider = this.checkUrlParamsForProvider(params?.clientOriginParams);

        }else{
          reservationService.setUrlOrigin(`parent=${params.clientOrigin}`);
        }
        reservationService.setUrlProviderOrigin(urlOriginProvider);
      }
      else{
        reservationService.setUrlOrigin(`iframe=` + urlIframe);

        let urlOriginProvider = URL_PROVIDER_ORIGINS.DIRECTO;
        if(params.clientOriginParams){
          urlOriginProvider = this.checkUrlParamsForProvider(params?.clientOriginParams);
        }
        reservationService.setUrlProviderOrigin(urlOriginProvider);
      }
      
    });
		this.routerSubscription = this.router.events.subscribe(
			(event: Event) => {
				if (event instanceof RouteConfigLoadStart) {
					asyncLoadCount++;

				} else if (event instanceof RouteConfigLoadEnd) {
					asyncLoadCount--;
				}

				this.isShowingRouteLoadIndicator = !!asyncLoadCount;
			},
			error => console.error(error)
		);
	}

	ngOnDestroy(): void {
		this.routerSubscription?.unsubscribe();
    this.routerParamsSubscription?.unsubscribe();
	}

  /**
   * Recibe unos parametros y busca unos identificadores de las campañas de publicidad como de Google o Facebook
   * y devuelve su valor
   * @param param parametros recibidos por el script al iframe
   * @returns
   */
  private checkUrlParamsForProvider(param: string): string{
    let provider: string = URL_PROVIDER_ORIGINS.OTROS;
    Object.values(URL_PROVIDER_ORIGINS_SEARCHER).forEach(providerSearcher => {
      if(param.includes(providerSearcher.SEARCH)){
        provider = providerSearcher.VALUE;
      }
    })
    return provider
  }
}
